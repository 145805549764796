import { Flex, useMediaQuery } from '@chakra-ui/react';
import AdminPageSelect from '../../../components/pageSelect/adminPageSelect';
import PageSizeSelection from '../../../components/pageSizeSelection/pageSizeSelection';

type CombinedPageSelectionProps = {

    handleOnPageChangeApproved: (page: number) => void;
  
    handleOnPageChangePending: (page: number) => void;
  
    pageNo: number;
  
    totalPages: number;
  
    view: string;
  
    pendingLoading: boolean;
  
    handleOnPageSizeChange: (size: number) => void;
  
  };

const CombinedPageSelection: React.FC<CombinedPageSelectionProps> =({

    handleOnPageChangeApproved,
  
    handleOnPageChangePending,
  
    pageNo,
  
    totalPages,
  
    view,
  
    pendingLoading,
  
    handleOnPageSizeChange
  
  }) => {
    const [isMobile] = useMediaQuery("(max-width: 1200px)");

    return (
        <Flex
            w="99%"
            direction={isMobile ? "column" : "row"}
            position="relative"
            justifyContent="space-between"
            alignItems={isMobile ? "" : "center"}
            mb={4}
        >   
            <PageSizeSelection
                options={[{ value: 10, label: '10' }, { value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }]}
                onPageSizeChange={handleOnPageSizeChange}
                w={200}
                h={5}
                ml={0}
                mr={0}
                display="flex"
                flexDirection="row"
            />
            <AdminPageSelect 
                onPageChangeApproved={handleOnPageChangeApproved} 
                onPageChangePending={handleOnPageChangePending} 
                currentPage={pageNo} 
                totalPages={totalPages} 
                view={view} 
                loading={pendingLoading} 
                w={900}
                ml={5}
                mr={0}
                mt={10}
            />
        </Flex>
        );
    }

export default CombinedPageSelection;