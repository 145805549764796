import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const NotificationCount = () => {
    const [count, setCount] = useState(0);
    const newChanges = useSelector((state: any) => state.regChange.newRegsCount);
    useEffect(() => {
        setCount(newChanges);
    }, [newChanges]);
    return (
        <>
            {count !== 0 ? (
                <span 
                style={{ 
                    position: 'relative',
                    bottom: '9px', 
                    right: '12px', 
                    backgroundColor: 'red', 
                    color: 'white', 
                    borderRadius: '50%', 
                    padding: '2px 5px', 
                    fontSize: '10px' 
                }}
                >
                    {count !== 0 ? count : ''}
                </span>
            ):""}
        </>
        
    );
};

export default NotificationCount;