import React from 'react';
import './style.css';

export function Footer() {
    return (
        <footer className="footer">
            <div className="container">

                <p className="float-lg-right">
                    <button className="link-button" onClick={() => window.scrollTo(0, 0)}>Back to top</button>
                </p>

                <div className="row">

                    <div className="col-sm-12">
                        <h4>
                            <a href="https://x.com/NuarcaLabs" target="_blank" rel="noopener noreferrer" title="Follow us on X"><i className="fab fa-x-twitter loud2 margin-right-4"></i><span className="sr-only">Twitter</span></a>
                            <a href="https://www.linkedin.com/company/nuarca-labs/" target="_blank" rel="noopener noreferrer" title="Follow us on LinkedIn"><i className="fab fa-linkedin-in loud2"></i><span className="sr-only">LinkedIn</span></a> 
                            <a href="https://discord.gg/x4uKk72j" target="_blank" rel="noopener noreferrer" title="Join us on Discord"><i className="fab fa-discord loud2"></i><span className="sr-only">Discord</span></a>
                        </h4>

                        <p>&copy;<span id="year"></span> NuArca</p>
                    </div>

                    <div className="col-sm-4">
                        <p> 304 Cambridge Rd.
                            <br/> Suite 210 <br/>Woburn, MA 01801

                            <br/>
                            <a href="tel:617.415.7714">617.415.7714</a>
                            <br/>
                            <a href="mailto: info@nuarca.com">email: info@nuarca.com</a>
                        </p>
                    </div>

                    <div className="col-sm-3">
                        <ul className="ul list-unstyled">
                            <li><a href="index.html">Home</a></li>

                            <li><a href="contact.html">Contact</a></li>

                        </ul>
                    </div>

                </div>
            </div>

        </footer>
    );
}