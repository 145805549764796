/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Spinner,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {
  reset,
  selectApprovedFiles,
  selectPendingApprovalFiles,
  updateApprovedFilesAtIndex,
  updatePendingApprovalFilesAtIndex,
} from '../../../store/reducers/files';
import './FileList.css';
import AccessAlert from '../AccessAlert/AccessAlert';
import { renderEditModal } from './renderEditModal';
import { renderDeleteFileDialog } from './renderDeleteFileDialog';
import { searchFileInput } from './searchFileInput';
import { renderFileListTabs } from './renderFileListTabs';
import { renderPendingFilesList } from './renderPendingFilesList';
import { handleFileManagement } from './HandleFileManagement';
import { createPartnerAndPersonaSelectors } from './createPartnerAndPersonaSelectors';
import { renderPageSizeSelector } from './pageSizeSelector';
import { useFetchPersonasPartners } from './fetchPersonasPartners';
import { fileFetchers } from './FetchPersonasPartnersProps';
import { getDocumentGroups, updateFileName } from '../../../services/fileService';
import useDisplayToast from '../../../utils/DisplayToast';
import { GroupSelector } from './groupSelector';
import { renderFileList } from './renderFileList';
import { update } from 'lodash';

const AdminFileList = () => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState<any | null>(null);
  const [options, setOptions] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const [view, setView] = useState<'approved' | 'pending' | 'add'>('pending');
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const { login } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [showtitleTextbox, setShowTitleTextbox] = useState(false);
  const [title, setTitle] = useState('');
  const [showTextbox, setShowTextbox] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [showURLTextbox, setShowURLTextbox] = useState(false);
  const [sourceURL, setSourceURL] = useState('');
  const inputTextColor = useColorModeValue('black', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedFileName, setEditedFileName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalApprovedCount, setTotalApprovedCount] = useState(0);
  const [totalPendingCount, setTotalPendingCount] = useState(0);
  const [approvedPageNo, setApprovedPageNo] = useState(1);
  const [pendingPageNo, setPendingPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [group, setGroup] = useState('all');
  const [groups, setGroups] = useState<string[]>([]);
  const [approvedTotalPages, setApprovedTotalPages] = useState(0);
  const [pendingTotalPages, setPendingTotalPages] = useState(0);
  const approvedFiles = useSelector(selectApprovedFiles);
  const pendingApprovalFiles = useSelector(selectPendingApprovalFiles);
  const alreadyCalledAuthorizeAndGetOptionsRef = useRef(false);
  const alreadyCalledFetchPartnersRef = useRef(false);
  const alreadyCalledFetchPersonasRef = useRef(false);
  const { ensureValidToken } = useAuth();
  const dispatch = useDispatch();
  const [ search, setSearch ] = useState('');
  const displayToast = useDisplayToast();
  const handleSave = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (!editedFileName.trim()) {
      displayToast(
        'Error',
        'File name cannot be empty. Please provide a valid name.',
        'error',
        3000
      );
      return;
    }
    setIsSaving(true);
    try {
      const response = await updateFileName(
        token,
        selectedPartnerName!,
        selectedPersona.name,
        fileToEdit.file_name,
        fileToEdit.id,
        editedFileName,
        profile?.user?.sub,
        profile?.organization
      );
      if (response.data.success) {
        displayToast(
          'Update Successful',
          'The file name has been successfully updated.',
          'success',
          3000
        );
        onClose();
      }
    } catch (error) {
      console.error('Error updating file name:', error);
      displayToast(
        'Error',
        'An error occurred while updating the file name. Please try again later.',
        'error',
        3000
      );
    } finally {
      dispatch(reset());
      alreadyCalledAuthorizeAndGetOptionsRef.current = false;
      setIsSaving(false);
      setFileToEdit(null);
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
      fetchGroups(selectedPartnerName!, selectedPersona.name);
      setDisplayName('');
      setShowTextbox(false);
      setSourceURL('');
      setShowURLTextbox(false);
      setTitle('');
      setShowTitleTextbox(false);
      callFurtherPages(
        'approved',
        1,
        selectedPartnerName,
        selectedPersona.name
      );
      callFurtherPages('pending', 1, selectedPartnerName, selectedPersona.name);
    }
  };

  const handleEditFileName = (editedFile: any) => {
    setFileToEdit(editedFile);
    onOpen();
  };

  const { fetchPersonas, fetchPartners } = useFetchPersonasPartners({
    ensureValidToken,
    alreadyCalledFetchPartnersRef,
    setPartners,
    alreadyCalledFetchPersonasRef,
    setLoading,
    setPersonas,
  });

  const checkFilesAndToast = (
    apiResponse: any,
    noFilesTitle: string,
    noFilesDescription: string
  ): boolean => {
    if (!apiResponse.filenames || !apiResponse.filenames.length) {
      displayToast(noFilesTitle, noFilesDescription, 'error', 3000);
      return false;
    }
    return true;
  };

  const callFurtherPages = async (
    fileType: string,
    pageNo?: number,
    partnerName?: string,
    personaName?: string,
    size?: number,
    search?: string,
    group?: string
  ) => {
    if (fileType === 'approved') {
      setApprovedLoading(true);
      try {
        const approvedFilesAPIResponse = await fetchApprovedFiles(
          partnerName!,
          personaName!,
          pageNo,
          size,
          group,
          search
        );
        const approvedFilesFound = checkFilesAndToast(
          approvedFilesAPIResponse,
          'No Files Found',
          search
            ? 'There were no approved files found in this search'
            : 'No approved files were found for the selected partner and persona.'
        );
        if (!approvedFilesFound) {
          setOptions([]);
          setTotalApprovedCount(0);
          setView('pending');
          return;
        } else {
          setLoading(false);
        }
        const approvedFilenamesArray = transformFileData(
          approvedFilesAPIResponse.filenames
        );
        setTotalApprovedCount(approvedFilesAPIResponse.count_doc);
        storeFilesArray(approvedFilenamesArray, 'approved', pageNo);
      } catch (error) {
        console.error('Error fetching approved files:', error);
        displayToast(
          'Fetch Error',
          'Failed to fetch files. Please try again later.',
          'error',
          3000
        );
        setOptions([]);
        setPendingFiles([]);
      } finally {
        setApprovedLoading(false);
      }
    } else {
      setPendingLoading(true);
      try {
        const pendingFilesAPIResponse = await fetchPendingFiles(
          partnerName!,
          personaName!,
          pageNo,
          size,
          group,
          search
        );
        const pendingFilesFound = checkFilesAndToast(
          pendingFilesAPIResponse,
          'No Files Found',
          'No files pending approval were found for the selected partner and persona.'
        );
        if (!pendingFilesFound) {
          return;
        } else {
          setLoading(false);
        }
        const pendingFilenamesArray = transformFileData(
          pendingFilesAPIResponse.filenames
        );
        setTotalPendingCount(pendingFilesAPIResponse.count_doc);
        storeFilesArray(pendingFilenamesArray, 'pending', pageNo);
      } catch (error) {
        console.error('Error fetching pending files:', error);
        displayToast(
          'Fetch Error',
          'Failed to fetch files. Please try again later.',
          'error',
          3000
        );
      } finally {
        setPendingLoading(false);
      }
    }
  };

  const storeFilesArray = (
    files: any[],
    fileType: string,
    pageNumber: number
  ) => {
    console.log('storeFilesArray:', files);
    if (fileType === 'approved') {
      const groupedApprovedFiles = groupFiles(files);
      setOptions(groupedApprovedFiles);
      dispatch(
        updateApprovedFilesAtIndex({
          index: pageNumber - 1,
          files: groupedApprovedFiles,
        })
      );
    } else {
      const groupedPendingFiles = groupFiles(files);
      setPendingFiles(groupedPendingFiles);
      dispatch(
        updatePendingApprovalFilesAtIndex({
          index: pageNumber - 1,
          files: groupedPendingFiles,
        })
      );
    }
  };

  const groupFiles = (files: any) => {
    return files.reduce((acc: Record<string, File[]>, file: any) => {
      const groupName = file.group_name || 'Uncategorized';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(file);
      return acc;
    }, {});
  };

  type CustomFile = {
    id: any;
    file_name: any;
    upload_time: any;
    display_name: any;
    group_name: any;
    last_modified_time: any;
    is_approved: any;
    has_image: any;
  };

  const transformFileData = (files: any[]): CustomFile[] => {
    return files.map((file: any) => ({
      id: file.id,
      file_name: file.name,
      upload_time: file.upload_time,
      display_name: file.display_name || file.name,
      group_name: file.group_name,
      last_modified_time: file.last_modified_time,
      is_approved: file.is_approved,
      has_image: file.has_image,
    }));
  };

  const fetchGroups = async (partnerName: string, personaName: string) => {
    try {
      const token = await ensureValidToken(localStorage.getItem('token'));
      const response = await getDocumentGroups(token, partnerName, personaName);
      const fetchedGroups = response.data!.map(
        (group: { group_name: string | null }) =>
          group.group_name === null ? 'all' : group.group_name
      );
      setGroups(fetchedGroups);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  const { fetchApprovedFiles, fetchPendingFiles } = fileFetchers(
    ensureValidToken,
    pageSize,
    group
  );

  const authorizeAndGetOptions = async (
    partnerName: string,
    personaName: string
  ) => {
    setLoadingOptions(true);
    if (!alreadyCalledAuthorizeAndGetOptionsRef.current) {
      alreadyCalledAuthorizeAndGetOptionsRef.current = true;
      try {
        const approvedFilesAPIResponse = await fetchApprovedFiles(
          partnerName,
          personaName,
          approvedPageNo
        );
        const pendingFilesAPIResponse = await fetchPendingFiles(
          partnerName,
          personaName,
          pendingPageNo
        );
        const pendingFilesFound = checkFilesAndToast(
          pendingFilesAPIResponse,
          'No Files Found',
          'No files pending approval were found for the selected partner and persona.'
        );
        const approvedFilesFound = checkFilesAndToast(
          approvedFilesAPIResponse,
          'No Files Found',
          'No approved files were found for the selected partner and persona.'
        );
        if (!pendingFilesFound || !approvedFilesFound) {
          return;
        }
        const approvedFilenamesArray = transformFileData(
          approvedFilesAPIResponse.filenames
        );
        setTotalApprovedCount(approvedFilesAPIResponse.count_doc);
        const pendingFilenamesArray = transformFileData(
          pendingFilesAPIResponse.filenames
        );
        setTotalPendingCount(pendingFilesAPIResponse.count_doc);
        storeFilesArray(approvedFilenamesArray, 'approved', approvedPageNo);
        storeFilesArray(pendingFilenamesArray, 'pending', pendingPageNo);
        updateTotalPages(
          approvedFilesAPIResponse.count_doc,
          pendingFilesAPIResponse.count_doc
        );
      } catch (error) {
        console.error('Error fetching options:', error);
        displayToast(
          'Fetch Error',
          'Failed to fetch files. Please try again later.',
          'error',
          3000
        );
      } finally {
        setLoadingOptions(false);
      }
    } else {
      console.info('Already called authorize and get options.');
      setOptions(approvedFiles[0]);
      setPendingFiles(pendingApprovalFiles[0]);
    }
  };

  const updateTotalPages = async (
    approvedFileCount: number,
    pendingFileCount: number
  ) => {
    const approvedTotalPages = Math.ceil(approvedFileCount / pageSize);
    const pendingTotalPages = Math.ceil(pendingFileCount / pageSize);
    setApprovedTotalPages(approvedTotalPages);
    setPendingTotalPages(pendingTotalPages);
  };

  const handleAddTitleClick = () => {
    setShowTitleTextbox(true);
  };

  const handleAddDisplayNameClick = () => {
    setShowTextbox(true);
  };

  const handleAddURLClick = () => {
    setShowURLTextbox(true);
  };

  const handleUpload = async () => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    if (uploadedFile && selectedPartner && selectedPersona) {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('pdf_file', uploadedFile);
        formData.append('partner_name', selectedPartnerName);
        formData.append('persona_name', selectedPersona.name);
        formData.append('user_id', profile?.userId);
        formData.append('uu_id', uuidv4());

        if (displayName && displayName.length > 0) {
          formData.append('display_name', displayName);
        }

        if (sourceURL && sourceURL.length > 0) {
          formData.append('source_url', sourceURL);
        }

        if (title && title.length > 0) {
          const additionalTitle = JSON.stringify({ title });
          formData.append('additional_title', additionalTitle);
        }

        const response = await axios.post(
          `${process.env.REACT_APP_LLM_API_URL}/upload-file-to-azure`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data.success === true) {
          setUploadedFile(null);
          displayToast(
            'Upload Successful',
            'The file has been successfully uploaded.',
            'success',
            3000
          );
          dispatch(reset());
          alreadyCalledAuthorizeAndGetOptionsRef.current = false;
          authorizeAndGetOptions(selectedPartnerName, selectedPersona.name);
          fetchGroups(selectedPartnerName!, selectedPersona.name);
          setSearch('');
          setDisplayName('');
          setShowTextbox(false);
          setSourceURL('');
          setShowURLTextbox(false);
          setTitle('');
          setShowTitleTextbox(false);
        } else {
          displayToast(
            'Upload Failed',
            'Failed to upload the file. Please try again later.',
            'error',
            3000
          );
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        displayToast(
          'Upload Failed',
          'Failed to upload the file. Please try again later.',
          'error',
          3000
        );
      } finally {
        setUploading(false);
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
    }
  };

  const filteredApprovedFiles = Object.keys(options).reduce(
    (acc: any, group: any) => {
      acc[group] = options[group];
      return acc;
    },
    {} as Record<string, any[]>
  );
  const filteredPendingFiles = Object.keys(pendingFiles).reduce(
    (acc: any, group: any) => {
      acc[group] = pendingFiles[group];
      return acc;
    },
    {} as Record<string, any[]>
  );

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
    const views = ['approved', 'pending'];
    for (const view of views) {
      callFurtherPages(
        view,
        view === 'approved' ? approvedPageNo : pendingPageNo,
        selectedPartnerName,
        selectedPersona.name,
        pageSize,
        event.target.value
      );
    }
  };

  const handleOpenDeleteAlert = (fileName: string) => {
    setFileToDelete(fileName);
    setIsDeleteAlertOpen(true);
  };

  const handleCloseDeleteAlert = () => {
    setIsDeleteAlertOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await handleDeleteFile(fileToDelete);
    handleCloseDeleteAlert();
    setIsDeleting(false);
  };

  const handleDeleteFile = async (fileName: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    try {
      const requestBody: any = {
        partner: selectedPartnerName,
        persona: selectedPersona.name,
        filename: fileName,
        auth: profile?.user?.sub,
        organization: profile?.organization,
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );
      if (response.data.delete === true) {
        displayToast(
          'File Deleted',
          `The file ${fileName} has been successfully deleted.`,
          'success',
          3000
        );
        authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
        fetchGroups(selectedPartnerName!, selectedPersona!.name);
        setSearch('');

        // fetchPendingFiles(selectedPartner);
      } else {
        displayToast(
          'Delete Failed',
          'Failed to delete the file. Please try again later.',
          'error',
          3000
        );
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      displayToast(
        'Delete Failed',
        'Failed to delete the file. Please try again later.',
        'error',
        3000
      );
    }
  };

  const handleFileClick = (fileName: string, displayName: string, documentId: number) => {
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    const url = `/file/chunk/${selectedPartnerName}/${selectedPersona!.name}/${
      selectedPersona!.id
    }/${fileName}/${displayName}`;
    window.open(url, '_blank');
  };

  const handlePendingFileClick = (
    fileName: string,
    displayName: string,
    documentId: number
  ) => {
    alreadyCalledAuthorizeAndGetOptionsRef!.current = false;
    const url = `/file/chunk-tags/${selectedPartnerName}/${selectedPersona.name}/${selectedPersona.id}/${fileName}/${displayName}/${documentId}`;
    window.open(url, '_blank');
  };

  const handleFileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedPersonaObj = personas.find(
      (persona: any) => persona.id.toString() === selectedId
    );
    alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    if (selectedPersonaObj) {
      setSelectedPersona({
        id: selectedPersonaObj!.id,
        name: selectedPersonaObj!.name,
      });
      setView('approved');
      authorizeAndGetOptions(selectedPartnerName!, selectedPersonaObj!.name);
      fetchGroups(selectedPartnerName!, selectedPersonaObj!.name);
      setSearch('');
      localStorage.setItem(
        'selectedPersona',
        JSON.stringify({
          id: selectedPersonaObj!.id,
          name: selectedPersonaObj!.name,
        })
      );
    }
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handlePartnerChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const partnerId = e.target.value;
    setSelectedPartner(partnerId);
    setSelectedPersona(null);

    const selectedPartnerObj = partners.find(
      (partner) => partner.id === parseInt(partnerId)
    );
    alreadyCalledAuthorizeAndGetOptionsRef!.current = false;
    if (selectedPartnerObj) {
      setSelectedPartnerName(selectedPartnerObj!.name);
      setLoading(true);
      await fetchPersonas(selectedPartnerObj!.id);

      // Save to localStorage
      localStorage.setItem(
        'selectedPartner',
        JSON.stringify({
          id: selectedPartnerObj!.id,
          name: selectedPartnerObj!.name,
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    updateTotalPages(totalApprovedCount, totalPendingCount);
  }, [pageSize]);

  const handleOnPageSizeChange = renderPageSizeSelector({
    setPageSize,
    callFurtherPages,
    selectedPartnerName,
    selectedPersona,
    setApprovedPageNo,
    setPendingPageNo,
    pageSize,
    setGroup,
    group,
    groups,
  });

  const handleOnPageChangeApproved = (page: number) => {
    setApprovedPageNo(page);
    if (
      approvedFiles[page - 1] != null ||
      approvedFiles[page - 1] !== undefined
    ) {
      setOptions(approvedFiles[page - 1]);
    } else {
      callFurtherPages(
        'approved',
        page,
        selectedPartnerName,
        selectedPersona.name
      );
    }
  };

  const handleOnPageChangePending = (page: number) => {
    setPendingPageNo(page);
    if (
      pendingApprovalFiles[page - 1] != null ||
      pendingApprovalFiles[page - 1] !== undefined
    ) {
      setPendingFiles(pendingApprovalFiles[page - 1]);
    } else {
      callFurtherPages(
        'pending',
        page,
        selectedPartnerName,
        selectedPersona.name
      );
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const storedPartner = localStorage.getItem('selectedPartner');
      const storedPersona = localStorage.getItem('selectedPersona');
      if (storedPartner) {
        const parsedPartner = JSON.parse(storedPartner);
        setSelectedPartner(parsedPartner.id.toString());
        setSelectedPartnerName(parsedPartner.name);
        await fetchPersonas(parsedPartner.id);
        if (storedPersona) {
          const parsedPersona = JSON.parse(storedPersona);
          setSelectedPersona(parsedPersona);
          authorizeAndGetOptions(parsedPartner.name, parsedPersona.name);
          fetchGroups(parsedPartner.name, parsedPersona.name);
          setSearch('');
        }
      }
    };

    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.info('NOT ADMIN');
    } else {
      fetchPartners();
      console.info('ADMIN');
    }
    if (approvedFiles[0] === null) {
      alreadyCalledAuthorizeAndGetOptionsRef.current = false;
    }
    initialize();
  }, []);

  useEffect(() => {
    if (selectedPartner && selectedPersona) {
      // alreadyCalledAuthorizeAndGetOptionsRef.current = false;
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
      fetchGroups(selectedPartnerName!, selectedPersona.name);
    }
  }, [selectedPartner, selectedPersona]);

  const { partnerSelect, personaSelect } = createPartnerAndPersonaSelectors(
    handlePartnerChange,
    selectedPartner,
    partners,
    handlePersonaChange,
    selectedPersona,
    loading,
    personas
  );

  return (
    <Box p={8} maxW="7xl" mx="auto" mt='50px'>
      {profile ? (
        !profile.isAdmin ? (
          <AccessAlert />
        ) : (
          <>
            {partnerSelect}

            {selectedPartner && personaSelect}

            {selectedPartner && selectedPersona && GroupSelector({
              setGroup,
              callFurtherPages,
              selectedPartnerName,
              selectedPersona,
              setApprovedPageNo,
              setPendingPageNo,
              group,
              groups,
            })}

            {selectedPartner && selectedPersona && (
              <>
                {searchFileInput(
                  search,
                  setSearch,
                  handleSearch,
                  inputTextColor
                )}
                {renderFileListTabs({ view, setView })}
                {loadingOptions && (
                  <Flex justifyContent="center" alignItems="center" minH="200px">
                    <Spinner size="xl" />
                  </Flex>
                )} 

                {view === 'approved' && !loadingOptions && (
                    renderFileList(
                      totalApprovedCount,
                      handleOnPageChangeApproved,
                      handleOnPageChangePending,
                      approvedPageNo,
                      approvedTotalPages,
                      'approved',
                      approvedLoading,
                      filteredApprovedFiles,
                      handleFileClick,
                      handleEditFileName,
                      handleOpenDeleteAlert,
                      handleOnPageSizeChange
                  ))}

                {view === 'pending' && !loadingOptions && (
                      renderPendingFilesList(
                        totalPendingCount,
                        handleOnPageChangeApproved,
                        handleOnPageChangePending,
                        pendingPageNo,
                        pendingTotalPages,
                        'pending',
                        pendingLoading,
                        filteredPendingFiles,
                        handlePendingFileClick,
                        handleEditFileName,
                        handleOpenDeleteAlert,
                        handleOnPageSizeChange
                      )
                )}


                {view === 'add' &&
                  handleFileManagement({
                    handleFileDrop,
                    uploadedFile,
                    showtitleTextbox,
                    handleAddTitleClick,
                    title,
                    setTitle,
                    inputTextColor,
                    showTextbox,
                    handleAddDisplayNameClick,
                    displayName,
                    setDisplayName,
                    showURLTextbox,
                    handleAddURLClick,
                    sourceURL,
                    setSourceURL,
                    uploading,
                    handleUpload,
                    handleFileSelect,
                    loadingOptions,
                    totalApprovedCount,
                    handleOnPageChangeApproved,
                    handleOnPageChangePending,
                    approvedPageNo,
                    approvedTotalPages,
                    view,
                    approvedLoading,
                    filteredApprovedFiles,
                    handleFileClick,
                    handleEditFileName,
                    handleOpenDeleteAlert,
                  })}

              </>
            )}
          </>
        )
      ) : null}
      {isDeleteAlertOpen &&
        renderDeleteFileDialog(
          isDeleteAlertOpen,
          cancelRef,
          handleCloseDeleteAlert,
          isDeleting,
          fileToDelete,
          handleConfirmDelete
        )}
      {isOpen &&
        renderEditModal({
          isOpen,
          onClose,
          inputTextColor,
          setEditedFileName,
          handleSave,
          isSaving,
        })}
    </Box>
  );
};

export default AdminFileList;
export interface RenderEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  inputTextColor: string;
  setEditedFileName: (value: string) => void;
  handleSave: () => Promise<void>;
  isSaving: boolean;
}

export interface RenderFileListTabsProps {
  view: 'approved' | 'pending' | 'add';
  setView: React.Dispatch<React.SetStateAction<'approved' | 'pending' | 'add'>>;
}