import { Flex, Image } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  const NuarcaLogo = () => {
    const { colorMode } = useColorMode();
    const logoSrc =
      colorMode === 'dark' ? '/img/logo_light.png' : '/img/logo_dark.png';

    return <Image src={logoSrc} alt="Nuarca Logo" width={'100%'} />;
  };
  return (
    <Flex
      display={{ base: 'none', xl: 'flex' }}
      alignItems="center"
      flexDirection="column"
    >
      <NuarcaLogo />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;