import {
  Flex,
  Icon,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdAutoAwesome, MdBolt, MdSunny } from 'react-icons/md';

interface AIModelProps {
  tab: number;
  onTabChange: (newTab: number) => void; 
}

const AIModel: React.FC<AIModelProps> = ({ tab, onTabChange }) => {

  const iconColor = useColorModeValue('brand.500', 'white');
  const buttonBg = useColorModeValue('white', 'whiteAlpha.100');
  const activeButtonBg = useColorModeValue('blue.500', 'blue.400');
  const textColor = useColorModeValue('navy.700', 'white');
  const activeTextColor = useColorModeValue('white', 'white');

  const handleTabChange = (newTab: number) => {
    onTabChange(newTab);
  };

  useEffect(() => {
    onTabChange(tab); // Update active tab when the external 'tab' prop changes
  }, [tab]);

  const fontSize = useBreakpointValue({ base: '15px', md: '18px' });
  const widthF = useBreakpointValue({ base: '110px', md: '180px' });
  const widthFS = useBreakpointValue({ base: '110px', md: '180px' });
  const height = useBreakpointValue({ base: '60px', md: '70px' });
  const marginTop = useBreakpointValue({
    base: '40px',
    md: '100px',
    '2xl': '80px',
  });

  // Use the same breakpoint logic to hide/show buttons
  const showButtons = useBreakpointValue({ base: false, xl: true });

  return (
    <Flex direction="column" w="100%" mb="-4" mt={marginTop}>
      {showButtons && (
        <Flex mx="auto" zIndex="2" w="max-content" mb="0px" borderRadius="60px">
          <Flex
            cursor="pointer"
            transition="0.3s"
            justify="center"
            align="center"
            bg={tab === 1 ? activeButtonBg : buttonBg}
            w={widthF}
            h={height}
            pe={'10px'}
            me={'2px'}
            mr="3"
            borderRadius="14px"
            color={tab === 1 ? activeTextColor : textColor}
            fontSize={fontSize}
            fontWeight="700"
            onClick={() => handleTabChange(1)}
          >
            <Flex
              borderRadius="full"
              justify="center"
              align="center"
              h="39px"
              w="30px"
            >
              <Icon
                as={MdAutoAwesome}
                width="20px"
                height="20px"
                color={iconColor}
              />
            </Flex>
            US Federal
          </Flex>
          <Flex
            cursor="pointer"
            transition="0.3s"
            justify="center"
            align="center"
            bg={tab === 2 ? activeButtonBg : buttonBg}
            w={widthFS}
            h={height}
            pe={'10px'}
            borderRadius="14px"
            color={tab === 2 ? activeTextColor : textColor}
            fontSize={fontSize}
            fontWeight="700"
            onClick={() => handleTabChange(2)}
          >
            <Flex
              borderRadius="full"
              justify="center"
              align="center"
              h="20px"
              w="25px"
            >
              <Icon as={MdBolt} width="20px" height="20px" color={iconColor} />
            </Flex>
            Federal & State
          </Flex>
          <Flex
            cursor="pointer"
            transition="0.3s"
            justify="center"
            align="center"
            bg={tab === 3 ? activeButtonBg : buttonBg}
            w={widthFS}
            h={height}
            pe={'10px'}
            ml="3"
            borderRadius="14px"
            color={tab === 3 ? activeTextColor : textColor}
            fontSize={fontSize}
            fontWeight="700"
            onClick={() => handleTabChange( 3)}
          >
            <Flex
              borderRadius="full"
              justify="center"
              align="center"
              h="20px"
              w="25px"
            >
              <Icon as={MdSunny} width="20px" height="20px" color={iconColor} />
            </Flex>
            Canada
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default AIModel;
