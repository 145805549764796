import { DeleteIcon, EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { IoMdImages } from "react-icons/io";
import { AdminFile } from "../../../models/files";

function uploadedDate(file: AdminFile) {
  return <Text fontSize="sm" color="gray.500" mr={4}>
    Uploaded:{" "}
    {new Date(file.last_modified_time).toLocaleString()}
  </Text>;
}

function imageAvailabilityTooltip(file: AdminFile) {
  return <Tooltip label="Image Available" aria-label="Image tooltip">
    <Box
      as={IoMdImages}
      color={file.has_image ? "white.500" : "transparent"}
      boxSize={{ base: 4, md: 5 }}
      mr={2}
      title={file.has_image ? "Has Image" : "No Image"} />
  </Tooltip>;
}

function buildEditFileTooltip(handleEditFileName: (editedFile: any) => void, file: AdminFile) {
  return <Tooltip label="Edit Name" aria-label="Edit tooltip">
    <Box
      as={EditIcon}
      ml={2}
      color="white"
      cursor="pointer"
      onClick={() => handleEditFileName(file)}
      boxSize={8}
      borderRadius="50%"
      p={2}
      _hover={{ bg: "blue.600" }} />
  </Tooltip>;
}

function fileDetailIconWithTooltipPending(handleApprovalFileClick: (fileName: string, displayName: string, id: number) => void, file: AdminFile) {
  fileDetailIconWithTooltip(handleApprovalFileClick, file);
}

function fileDetailIconWithTooltip(handleFileClick: (fileName: string, displayName: string, id?: number) => void, file: AdminFile) {
  return <Tooltip
    label="Show Details"
    aria-label="Show Details tooltip"
  >
    <Box
      as={InfoOutlineIcon}
      color="white"
      cursor="pointer"
      onClick={() => handleFileClick(
        file.file_name,
        file.display_name || file.file_name,
        file.id
      )}
      boxSize={8}
      ml={2}
      borderRadius="50%"
      p={2}
      _hover={{ bg: "blue.600" }} />
  </Tooltip>;
}

function fileName(handleFileClick: (fileName: string, displayName: string, id: number) => void, file: AdminFile) {
  return <Box
    onClick={() => handleFileClick(
      file.file_name,
      file.display_name || file.file_name,
      file.id
    )}
    cursor="pointer"
    flex="1"
  >
    <Text>{(file.display_name || file.file_name).replace(/_/g, ' ')}</Text>
  </Box>;
}

function deleteTooltip(handleOpenDeleteAlert: (fileName: string) => void, file: AdminFile) {
  return <Tooltip
    label="Delete"
    aria-label="Delete tooltip"
    hasArrow
    bg="red.500"
  >
    <Box
      as={DeleteIcon}
      color="white"
      cursor="pointer"
      onClick={() => handleOpenDeleteAlert(file.file_name)}
      ml={2}
      boxSize={8}
      borderRadius="50%"
      p={2}
      _hover={{ bg: "red.600" }} />
  </Tooltip>;
}

function noFilesToDisplay(fileType: string) {
  return <Flex justifyContent="center" alignItems="center" height="100%">
    <Text>There are currently no {fileType} files to display</Text>
  </Flex>;
}

export {
  deleteTooltip,
  fileName,
  fileDetailIconWithTooltip,
  fileDetailIconWithTooltipPending,
  buildEditFileTooltip,
  imageAvailabilityTooltip,
  uploadedDate,
  noFilesToDisplay
}