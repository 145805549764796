import {
  FormControl,
  FormLabel,
  Select
} from '@chakra-ui/react';

interface RenderPageSizeSelectorProps {
  setPageSize: (size: number) => void;
  callFurtherPages: (
    fileType: string,
    pageNo?: number,
    partnerName?: string,
    personaName?: string,
    size?: number,
    search?: string,
    group?: string
  ) => Promise<void>;
  selectedPartnerName: string;
  selectedPersona: any;
  setApprovedPageNo: (page: number) => void;
  setPendingPageNo: (page: number) => void;
  pageSize: number;
  setGroup: (group: string) => void;
  group: string;
  groups: string[];
}
export function renderPageSizeSelector({
  setPageSize, callFurtherPages, selectedPartnerName, selectedPersona, setApprovedPageNo, setPendingPageNo, pageSize, setGroup, group, groups,
}: RenderPageSizeSelectorProps) {
  console.log('selectedPartner', selectedPartnerName);
  return () => {
    return (
      <>
        <FormControl mb={4}>
          <>
            <FormLabel htmlFor="page-size-select" fontWeight="bold">
              Page Size
            </FormLabel>
            <Select
              title="Page Size Selector"
              aria-label="Page Size Selector"
              id="page-size-select"
              placeholder="Select page size"
              onChange={(e) => {
                const newSize = Number(e.target.value);
                setPageSize(newSize);
                callFurtherPages(
                  'approved',
                  1,
                  selectedPartnerName,
                  selectedPersona.name,
                  newSize
                );
                callFurtherPages(
                  'pending',
                  1,
                  selectedPartnerName,
                  selectedPersona.name,
                  newSize
                );
                setApprovedPageNo(1);
                setPendingPageNo(1);
              }}
              mb={4}
              value={pageSize}
            >
              {[25, 50, 75, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </>
        </FormControl>
      </>
    );
  };
}
