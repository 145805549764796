import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import useAuth from "hooks/auth";
import { MdEdit, MdLock } from "react-icons/md";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { RootState } from "store/store";
import CustomCard from "./Card";
import useDisplayToast from "../../utils/DisplayToast";

export default function TemplateCard(props: {
  src: string;
  name: string;
  description: string;
  link: string;
  edit?: string;
  action?: any;
  admin?: boolean;
  locked?: boolean;
  comingSoon?: boolean;
}) {
  const {
    src,
    name,
    description,
    link,
    edit,
    admin,
    locked = false,
    comingSoon = false,
  } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const gray = useColorModeValue("gray.500", "white");
  const overlayOpacity = 0.7;
  const overlayOpacityDown = 0.3;
  const history = useHistory();
  const { login } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const displayToast = useDisplayToast();

  const handleLockedClick = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
    displayToast(
      "Upgrade Required",
      "You need to upgrade your plan to access this feature.",
      "info",
      2000
    );
    history.push("/compliance/pricing");
  };

  const cardContent = (
    <CustomCard
      h="100%"
      py="24px"
      px="24px"
      position="relative"
      overflow="hidden"
      _hover={{
        ".description": { opacity: 1, visibility: "visible" },
        ".content": { opacity: 0 },
      }}
      onClick={locked ? handleLockedClick : null}
    >
      <Flex
        my="auto"
        h="100%"
        direction="column"
        align="center"
        justify="center"
        className="content"
        transition="opacity 0.3s"
      >
        {locked ? (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            zIndex="1"
            cursor="pointer"
          >
            <Icon
              as={MdLock}
              color={textColor}
              w="48px"
              h="48px"
              opacity={overlayOpacity}
            />
          </Box>
        ) : null}

        {comingSoon ? (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            zIndex="1"
            cursor="pointer"
          >
            <Text
              fontSize="2xl"
              color={textColor}
              fontWeight="700"
              opacity={overlayOpacity}
            >
              Coming Soon
            </Text>
          </Box>
        ) : null}
        <Flex align="start" w="100%">
          {admin ? (
            <Flex ms="auto">
              <NavLink to={edit ? edit : "/admin/edit-template"}>
                <Button
                  w="24px"
                  h="24px"
                  _hover={{}}
                  _focus={{}}
                  _active={{}}
                  bg="none"
                >
                  <Icon w="24px" h="24px" as={MdEdit} color={gray} />
                </Button>
              </NavLink>
            </Flex>
          ) : null}
        </Flex>
        <Box textAlign="center" mt="20px">
          <Text
            fontSize="xl"
            color={comingSoon || locked ? gray : textColor}
            fontWeight="700"
            mb="15px"
            opacity={comingSoon || locked ? overlayOpacityDown : 1}
          >
            {name}
          </Text>
        </Box>
        <Box textAlign="center" mb="15px">
          <Image
            src={src}
            alt={name}
            maxH="70px"
            mt="15px"
            opacity={locked || comingSoon ? 0.5 : 1}
          />
        </Box>
      </Flex>
      <Box
        className="description"
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg={useColorModeValue("white", "navy.700")}
        opacity={0}
        visibility="hidden"
        transition="opacity 0.3s, visibility 0.3s"
        p="24px"
      >
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="700"
          mb="8px"
          textAlign="center"
        >
          {name}
        </Text>
        <Text fontSize="md" color={gray} fontWeight="500" textAlign="center">
          {description}
        </Text>
      </Box>
    </CustomCard>
  );

  return (
    <Box pointerEvents={locked || comingSoon ? "auto" : "auto"}>
      {locked || comingSoon ? (
        cardContent
      ) : (
        <NavLink to={link}>{cardContent}</NavLink>
      )}
    </Box>
  );
}
