import axios from "axios";

export const getPartners = async (token: string) => {
    const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-partners`,
        {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
        },
        }
    );
    return response;
}

export const getPersonas = async (token: string, partnerId: number) => {
    const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-personas/${partnerId}`,
        {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
        },
        }
    );
    return response.data;
}