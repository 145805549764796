import {
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import useAuth from 'hooks/auth';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { selectedFilesSection } from './selectedFilesSection';
import { sendButton } from './sendButton';
import { textEntryAreaChat } from './textEntryAreaChat';

const ChatFooter: React.FC<any> = ({
  handleSendMessage,
  loading,
  handleRemoveFile,
  handleFileChange,
  selectedFiles,
  setSendButtonClicked,
  sendButtonClicked,
}) => {
  const location = useLocation();
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const inputColor = useColorModeValue('navy.700', 'white');
  const { login } = useAuth();
  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
  };
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isChat, setIsChat] = useState(false);

  let placeholderMsg = '';
  if (!profile?.idToken) {
    placeholderMsg = 'Login to ask your first question.';
  } else {
    if (isChat) {
      placeholderMsg = 'Type your message here...';
    } else {
      placeholderMsg = 'Ask a question about this change.';
    }
    
  }

  useEffect(() => {
    if (location.pathname === '/compliance/chat') {
      setIsChat(true);
    }
  }, [location.pathname]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight/1.2}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Flex w="100%" mt="5" align="flex-end" display={isMobile ? "unset" : "flex"}>
      <Flex
        w="100%"
        minH="40px"
        maxH="200px"
        flexDirection="column"
        position="relative"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="45px"
        pt="5px"
        pb="5px"
        px="20px"
        me={'10px'}
        overflowY="auto"
        _focusWithin={{
          borderColor: 'gray.400',
          boxShadow: '0 0 0 2px blue.500',
        }}
      >
        {isChat && !sendButtonClicked && selectedFiles.length > 0 && (
          selectedFilesSection(selectedFiles, inputColor, handleRemoveFile)
        )}

        {textEntryAreaChat({
          fileInputRef,
          handleFileInputChange,
          textareaRef,
          inputMessage,
          inputColor,
          placeholderColor,
          placeholderMsg,
          dispatch,
          adjustTextareaHeight,
          profile,
          handleLogIn,
          loading,
          handleSendMessage,
          setSendButtonClicked,
          isChat,
          textColor
        })}
      </Flex>

      {sendButton(loading, isMobile, handleSendMessage, selectedFiles, setSendButtonClicked)}
    </Flex>
  );
};

export default ChatFooter;


