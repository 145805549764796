import {
  isToday,
  isWithinInterval,
  isYesterday,
  subDays,
  subMonths,
} from 'date-fns';

export const stripHtmlTags = (htmlString: string) => {
  const result = htmlString
    .replace(/<FILETAGSTART>.*?<\/FILETAGEND>|<\/?[^>]+(>|$)/g, '')
    .trim();

  return result;
};

export const groupChatHistoryByDate = (chatHistory: any[]) => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const last7Days = subDays(today, 7);
  const lastMonth = subMonths(today, 1);

  const groupedHistory: { [key: string]: any[] } = {
    Today: [],
    Yesterday: [],
    'Last 7 Days': [],
    'Last Month': [],
    Earlier: [],
  };

  chatHistory.forEach((chat) => {
    const chatDate = new Date(chat.date);
    if (isToday(chatDate)) {
      groupedHistory.Today.push(chat);
    } else if (isYesterday(chatDate)) {
      groupedHistory.Yesterday.push(chat);
    } else if (isWithinInterval(chatDate, { start: last7Days, end: today })) {
      groupedHistory['Last 7 Days'].push(chat);
    } else if (isWithinInterval(chatDate, { start: lastMonth, end: today })) {
      groupedHistory['Last Month'].push(chat);
    } else {
      groupedHistory.Earlier.push(chat);
    }
  });
  const filteredHistory: { [key: string]: any[] } = {};
  Object.keys(groupedHistory).forEach((key) => {
    if (groupedHistory[key].length > 0) {
      filteredHistory[key] = groupedHistory[key];
    }
  });

  return filteredHistory;
};
