import { FormControl, FormLabel, useMediaQuery } from '@chakra-ui/react';
import { Select, ActionMeta } from 'chakra-react-select';
import React, { useState } from 'react';

interface PageSizeSelectionProps {
    options: { value: number; label: string }[];
    onPageSizeChange: (size: number) => void;
    w?: number;
    h?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
    display?: string;
    flexDirection?: string;
}

const PageSizeSelection: React.FC<PageSizeSelectionProps> = ({ options, onPageSizeChange, w, h, mt, mb, ml, mr, display, flexDirection  }) => {
    const [isMobile] = useMediaQuery('(max-width: 1200px)');
    const [selectedSize, setSelectedSize] = useState<{ value: number; label: string }>(options[0]);

    const handleChange = (newValue: { value: number; label: string; }, actionMeta: ActionMeta<{ value: number; label: string; }>) => {
        const newSize = newValue.value;
        setSelectedSize(newValue);
        onPageSizeChange(newSize);
    };

    return (
    <FormControl 
         {...(isMobile ? { 
          w: '100%', 
          mb: 4
         } : {
          display: display === "flex" ? 'flex' : undefined,
          flexDirection: flexDirection === "row" ? 'row' : 'column',
          mt: mt ?? 'auto',
          w: w ?? 'auto',
          h: h ?? 'auto',
          mb: mb ?? 4,
          ml: ml ?? 'auto',
          mr: mr ?? 'auto' 
         })}
         zIndex={11}
         >
         <FormLabel htmlFor='page-size-select' fontWeight="bold" pt={display === "flex" ? 2 : 0}>
          Page Size
         </FormLabel>
         <Select 
          id='page-size-select' 
          value={selectedSize} 
          onChange={handleChange}
          options={options}
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              zIndex: 11,
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 11,
            }),
          }}
         />
     </FormControl>
    );
};

export default PageSizeSelection;