'use client';
import {
  Flex,
  Button,
  FormLabel,
  Select,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import MessageBox from 'components/MessageBox';
import Card from 'components/card/Card';
import useAuth from 'hooks/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ComingSoonMessage from '../CommingSoon';
import PremiumModal from 'components/modals/PremiumModal';
import useDisplayToast from '../../../../utils/DisplayToast';

export default function PolicyGeneator() {
  const [topic, setTopic] = useState<string>('');
  const title = '';
  const [language, setLanguage] = useState<
    | ''
    | 'English'
    | 'Chinese'
    | 'Spanish'
    | 'Arabic'
    | 'Hindi'
    | 'Italian'
    | 'Portuguese'
    | 'Russian'
    | 'Japanese'
    | 'Romanian'
    | 'German'
  >('');
  const outputCode = '';
  const model = 'gpt-3.5-turbo';
  const loading = false;
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' },
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const { login } = useAuth();
  const displayToast = useDisplayToast();

  const handleTranslate = async () => {
    const maxCodeLength = model === 'gpt-3.5-turbo' ? 60 : 60;
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    if (!topic) {
      alert('Please enter your topic.');
      return;
    }

    if (title.length > maxCodeLength) {
      alert(
        `Please enter code less than ${maxCodeLength} characters. You are currently at ${title.length} characters.`,
      );
      return;
    }

    if (!language) {
      alert('Please choose your Policy Type.');
      return;
    }
    displayToast('Generating Policy...', 'Please wait...', 'info', 3000);
  };

  const handleTopic = (Event: any) => {
    setTopic(Event.target.value);
  };

  const handleLanguage = (Event: any) => {
    setLanguage(Event.target.value);
  };

  return (
   
    <div style={{marginTop: "110px"}}>
    <Flex
      w="100%"
      direction="column"
      position="relative"
      mt={{ base: '70px', md: '0px', xl: '0px' }}
    >
    <ComingSoonMessage/>
      <Flex
        mx="auto"
        w={{ base: '100%', md: '100%', xl: '100%' }}
        maxW="100%"
        justify="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Card
          minW={{ base: '100%', md: '40%', xl: '476px' }}
          maxW={{ base: '100%', md: '40%', xl: '476px' }}
          h="min-content"
          me={{ base: '0px', md: '20px' }}
          mb={{ base: '20px', md: '0px' }}
        >
          <Text fontSize={'30px'} color={textColor} fontWeight="800" mb="10px">
            Policy Topic
          </Text>
          <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
            Provide as much information as posssible about your existing policies in this area.
          </Text>
          <Textarea
            border="1px solid"
            borderRadius={'10px'}
            borderColor={borderColor}
            p="15px 20px"
            mb="28px"
            minH="324px"
            fontWeight="500"
            _focus={{ borderColor: 'none' }}
            color={textColor}
            placeholder="Type here the topic..."
            _placeholder={placeholderColor}
            onChange={handleTopic}
          />
          
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={'lang'}
            fontSize="md"
            color={textColor}
            letterSpacing="0px"
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            Select your Policy type
          </FormLabel>
          <Select
            border="1px solid"
            borderRadius={'10px'}
            borderColor={borderColor}
            h="60px"
            id="lang"
            _focus={{ borderColor: 'none' }}
            mb="28px"
            onChange={handleLanguage}
          >
            <option value="Return policy" className='white-background'>Return policy</option>
            <option value="Refund policy"  className='white-background'>Refund policy</option>
            <option value="Privacy policy"  className='white-background'>Privacy policy</option>
          </Select>

          

          <Button
            py="20px"
            px="16px"
            fontSize="md"
            variant="primary"
            borderRadius="45px"
            w={{ base: '100%' }}
            mt="28px"
            h="54px"
            onClick={handleTranslate}
            isLoading={loading ? true : false}
            _hover={{
              boxShadow:
                '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
              bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
              _disabled: {
                bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
              },
            }}
          >
            Generate your PolicyGeneator
          </Button>
        </Card>
        <Card maxW="100%" h="100%">
          <Text fontSize={'30px'} color={textColor} fontWeight="800" mb="10px">
            Policy Output
          </Text>
          <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
          All policies are considered drafts and must be reviewed by appropriate internal committees before being implemented
          </Text>
          <MessageBox output={outputCode} />
          <Button
            variant="transparent"
            border="1px solid"
            borderColor={borderColor}
            borderRadius="full"
            maxW="160px"
            ms="auto"
            fontSize="md"
            w={{ base: '300px', md: '420px' }}
            h="54px"
            onClick={() => {
              if (outputCode) navigator.clipboard.writeText(outputCode);
              displayToast(outputCode ? 'Copied to clipboard' : 'Generate some content first!', '', 'success', 3000);
            }}
          >
            Copy text
          </Button>
        </Card>
      </Flex>
    </Flex>

<PremiumModal />
    </div>
  );
}
